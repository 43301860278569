import React from 'react' 
import JotformEmbed from 'react-jotform-embed'

const PageRequestInfoSection = () => (
  <div className="bg-gray-100">
    <div className="lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4">
      <h2 className="font-display text-gray-900 text-3xl sm:text-2xl leading-snug">
        We’d love to hear from you! Please call us at <a href='tel:714-891-8804' className='underline'>(714) 891-8804</a> or email us using the form below.
      </h2>

      <div className='w-9/12 sm:w-full'>
        <JotformEmbed src="https://form.jotform.com/233333606200038" />
      </div>
    </div>
  </div>
)

export default PageRequestInfoSection