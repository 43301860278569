import React from "react"
import MainContainer from "../containers/MainContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import PageRequestInfoSection from "../sections/PageRequestInfoSection"

export default () => (
  <MainContainer>
    <HeadData 
      title='Request Information' 
      description='If you need help or would like more information, please call us at (714) 891-8804 or email us using the contact form.' 
    />

    <PageHeaderImageBg>
      <HeaderNav />
      <PageHeaderText
        title='Request Information'
      />
    </PageHeaderImageBg>

    <PageRequestInfoSection />
  </MainContainer>
)
